import React from 'react'
import sleepybrosLogo from './assets/sleepybros.png';
import Card from './components/Card';
import GhostItem from './components/GhostItem';
import Footer from './components/Footer';

const App = () => {

  const apps = [
    {
      title: "DoMore.",
      description: "Social Media App focused on productivity and motivational explore page for sharing daily wins.",
      image: "https://i.postimg.cc/hGtHXrn7/temp-Imagez3n-V8-Q.avif",
      targetLink: "",
      githubLink: "",
    },
    {
      title: "MediaDL",
      description: "All-in-one video downloader for Instagram and TikTok videos. Created with React and Material UI.",
      image: "https://i.postimg.cc/5t8pm193/Screenshot-2024-03-13-103018.png",
      targetLink: "",
      githubLink: "",
    },
    {
      title: "Youtube Plus",
      description: "Youtube mockup with a simple and clean design created with React.js and Youtube API from RapidAPI.",
      image: "https://i.postimg.cc/W1My5Y6t/Screenshot-2022-09-25-233619.png",
      targetLink: "https://cleanyoutube.netlify.app/",
      githubLink: "https://github.com/jay3393/newYoutube",
    },
    {
      title: "CryptoExplore",
      description: "Cryptocurrency tracker showing statistics such as price, market cap, volume, and the latest news.",
      image: "https://i.postimg.cc/Ghk0fpbc/Screenshot-2022-09-25-234022.png",
      targetLink: "https://cryptoexplore.netlify.app/",
      githubLink: "https://github.com/jay3393/cryptoverse",
    },
    {
      title: "Crave",
      description: "Food enthusiasts, chefs, and homecook's social media platform to share and browse through thousands of recipes.",
      image: "https://i.postimg.cc/66VJj3WB/Screenshot-2022-09-25-234905.png",
      targetLink: "",
      githubLink: "https://github.com/CSE370HCI/fantastic",
    },
    {
      title: "Pathfinding Visualizer",
      description: "A sandbox playground to help visualize different pathfinding algorithms through drawn scenarios.",
      image: "https://i.postimg.cc/MG0mkhvf/Screenshot-2022-09-25-235443.png",
      targetLink: "https://pathfindvisual.netlify.app/",
      githubLink: "https://github.com/jay3393/pathfinding-visualizer",
    },
    {
      title: "Zingl Chat App",
      description: "Simple live chat app with register and login using websockets to send and receive real time messages.",
      image: "https://i.postimg.cc/vZhcb3rD/Screenshot-2022-09-28-142200.png",
      targetLink: "",
      githubLink: "https://github.com/jay3393/zingl-chat-app",
    },
    {
      title: "Boids Flocking Simulation",
      description: "Flocking simulation using 2dVector and polar coordinates. Visualizer built with Python, Pygame.",
      image: "https://i.postimg.cc/jSPb5Zmw/126924956-89628af1-e0f4-4563-a597-a3832d1b360e.gif",
      targetLink: "",
      githubLink: "https://github.com/jay3393/Boid-Flock",
    },
    {
      title: "Maze Generation Algorithm",
      description: "Generates mazes using randomized Kruskal's Algorithm then solves with various algorithms.",
      image: "https://i.postimg.cc/9XTGXL79/126923935-703cded3-eb68-4bd5-bfff-c37f600ef3de.gif",
      targetLink: "https://github.com/jay3393/Amazely",
      githubLink: "https://github.com/jay3393/Amazely",
    },
    {
      title: "Shop Alert",
      description: "Web scrapper for product price updates and alerts via Discord. Built with JavaScript and Discord API.",
      image: "https://i.postimg.cc/zfrfjkfs/126921633-fbbb0d52-9747-4f4a-ad85-119dd2ea2ad9.png",
      targetLink: "https://github.com/jay3393/ShopAlert",
      githubLink: "https://github.com/jay3393/ShopAlert",
    },
    // {
    //   title: "",
    //   description: "",
    //   image: "",
    //   targetLink: "",
    //   githubLink: "",
    // },
  ];

  return (
    <div className='bg-gradient-to-br from-black from-25% to-[#090520] min-h-screen'>

      <div className='pl-5 pt-5 min-w-screen'>
        <div className='flex'>
            <a href='/' className='flex items-center gap-3'>
            <img src={sleepybrosLogo} alt='Sleepybros Logo' className='w-16 h-16'/>
            <div>
              <h1 className='text-neutral-50 text-3xl font-bold'>Sleepybros</h1>
              <span className='text-neutral-50 text-sm font-semibold'>App Development</span>
            </div>
          </a>
        </div>
      </div>

      <div className='h-28'></div>

      <div className='flex flex-wrap justify-center gap-x-12 gap-y-[32px]'>
        {apps.map((cardDetails, index) => (
          <Card
            key={index}
            title={cardDetails.title}
            shortDescription={cardDetails.description}
            imageSrc={cardDetails.image}
            githubLink={cardDetails.githubLink}
            targetLink={cardDetails.targetLink}
          />
        ))}
        {/* <GhostItem item={<Card />}/>
        <GhostItem item={<Card />}/> */}
      </div>

      <div className='h-28'></div>
      <Footer />

    </div>
  )
}

export default App